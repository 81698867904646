import React from 'react'

const CharacterPage = () => {
    return (
        <div>
            <bold>IN DEVELOPMENT</bold>
        </div>
    )
}

export default CharacterPage